import React from "react";
import { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "@/components/notFound";

const PrivacyPages = lazy(() => import("@/pages/privacyPages"));
const Business = lazy(() => import("@/pages/business"));
const RingtoneTwo = lazy(() => import("@/pages/ringtoneTwo"));
const Article = lazy(() => import("@/pages/article"));

// 路由懒加载，需配合Suspense使用
const lazyLoad = (children) => {
  return <Suspense fallback={""}>{children}</Suspense>;//Suspense 组件在没有数据的时候会优先挂起渲染，提高用户体验
};

const AppRouter = () => {
  return useRoutes([
    {
      path: "/main",
      element: lazyLoad(<Article />),
    },
    {
      path: "/privacypage",
      element: lazyLoad(<PrivacyPages />),
    },
    {
      path: "/business",
      element: lazyLoad(<Business />),
    },
    {
      path: "/",
      element: lazyLoad(<Article />),
    },

    { path: "*", element: <NotFound /> },
  ]);
};

export default AppRouter;
